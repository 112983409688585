export enum RouteKeys {
  Home = '/',
  TrimLEvel = '/trim-level/:year/:modelCode/',
  EditionVariant = '/edition/modelyear/:year/model/:modelCode/trimcode/:trim/',
  BuildVehicle = '/build-vehicle/:id',
  PayNow = '/pay-now/',
  PaymentCallback = '/payment-callback/',
  Auth = '/auth/',
  MyAccount = '/my-account/',
  NotFound = '/page-not-found',
  Build = '/build',
  ProductDetail = '/detail/:model',
  News = '/news',
  NewsDetail = '/news/detail/:slug',
  Contact = '/contactus',
  Owners = '/owners',
  Offers = '/offers',
  Insurance = '/insurance',
  Finance = '/finance',
  FinanceOpt = '/financeOpt',
  VehicleProtection = '/vehicle-protection',
  AboutUs = '/about-us',
  PrivacyPolicy = '/privacy-policy',
  Terms = '/terms',
  Disclaimer = '/disclaimer',
  EnquirySuccess = '/enquiry',
  TrimLevelYear = '/trim-level-year/:modelId',
  ForgotPassword = '/auth/forgot-password',
  PaymentLink = '/paymentrequest',
  PaymentLinkSuccess = '/paymentrequest-success',
  DiscountApproval = '/approve-discount',
  ApprovalStatus = '/approve-discount/approvalStatus',
  CustomerPaymentLink = '/customer-paymentrequest',
  CustomerPaymentLinkSuccess = '/customer-paymentrequest-success',
  QuickForms = '/quick-forms/:formType',
  CampaignPage = '/campaign-vehicle-form/:vehicle',
  // CampaignThankyou = '/campaign-thankyou',
  CampaignThankyou = '/campaign-vehicle-form/thank-you/:vehicle',

  ApproveConfigurationRefund = '/approve-configuration-refund',
  ApproveConfigurationRefundStatus = '/approve-configuration-refund/approvalStatus',

  ApproveCustomerRefund = '/approve-customer-refund',
  ApproveCustomerRefundStatus = '/approve-customer-refund/approvalStatus',

  ApproveInvoiceCancel = '/approve-invoice-cancel',
  ApproveInvoiceCancelStatus = '/approve-invoice-cancel/approvalStatus',

  ApproveOrderCancel = '/approve-order-cancel',
  ApproveOrderCancelStatus = '/approve-order-cancel/approvalStatus',

  ApproveVin = '/approve-vin',
  ApproveVinStatus = '/approve-vin/approvalStatus',

  ApproveVinExtension = '/approve-vin-extension',
  ApproveVinExtensionStatus = '/approve-vin-extension/approvalStatus',

  ProcessConfigurationRefund = '/process-configuration-refund',
  ProcessConfigurationRefundStatus = '/process-configuration-refund/approvalStatus',

  ProcessCustomerRefund = '/process-customer-refund',
  ProcessCustomerRefundStatus = '/process-customer-refund/approvalStatus',

  SpecialApproveBooking = '/special-approve-booking',
  SpecialApproveBookingStatus = '/special-approve-booking/approvalStatus',

  LostLead = '/lost-lead',
  LostLeadStatus = '/lost-lead/approvalStatus',

  VerifyEmail = '/verify-email',
  TestDriveRideOut = '/testdrive-rideout',
}

