import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageLoader } from '../../components/loader';
import UnAuthenticateRoute from './UnAuthenticatedRoute';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { AppSettingsStateViewModel } from 'alg-ecom-frontend-core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const TrimLevel = lazy(() => import('../trim-level'));
const EditionVarient = lazy(() => import('../edition-variant'));
const BuildVehicle = lazy(() => import('../build-vehicle'));
const Landing = lazy(() => import('../landing'));
const PaymentSelection = lazy(() => import('../payment/PayNow'));
const PaymentSuccess = lazy(() => import('../payment/PaymentSuccess'));
const AuthPage = lazy(() => import('../auth/AuthPage'));
const MyAccount = lazy(() => import('../dashboard'));
const ProductDetail = lazy(() => import('../product-detail/ProductDetail'));
const NewsListing = lazy(() => import('../news/NewsListing'));
const NewsDetail = lazy(() => import('../news/NewsDetail'));
const Contact = lazy(() => import('../cms/contact'));
const Owners = lazy(() => import('../cms/owners'));
const Offers = lazy(() => import('../cms/offers'));
const Insurance = lazy(() => import('../cms/insurance'));
const Finance = lazy(() => import('../cms/finance'));
const FinanceOpt = lazy(() => import('../cms/financeOpt'));
const VehicleProtection = lazy(() => import('../cms/vehicleProtection'));
const AboutUs = lazy(() => import('../cms/about'));
const PrivacyPolicy = lazy(() => import('../cms/privacypolicy'));
const Terms = lazy(() => import('../cms/terms'));
const Disclaimer = lazy(() => import('../cms/disclaimer'));
const EnquirySuccess = lazy(
  () => import('../product-detail/enquiry/EnquirySuccess')
);
const SubRoutes = lazy(() => import('./SubRoutes'));


const ApprovalStatus = lazy(
  () => import('../discount-approval/ApprovalStatus')
);

const ApproveConfigurationRefund = lazy(
  () => import('../approve-configuration-refund/index')
);
const ApproveConfigurationRefundStatus = lazy(
  () => import('../approve-configuration-refund/ApprovalStatus')
);

const ApproveCustomerRefund = lazy(
  () => import('../approve-customer-refund/index')
);
const ApproveCustomerRefundStatus = lazy(
  () => import('../approve-customer-refund/ApprovalStatus')
);

const ApproveInvoiceCancel = lazy(
  () => import('../approve-invoice-cancel/index')
);
const ApproveInvoiceCancelStatus = lazy(
  () => import('../approve-invoice-cancel/ApprovalStatus')
);

const ApproveOrderCancel = lazy(() => import('../approve-order-cancel/index'));
const ApproveOrderCancelStatus = lazy(
  () => import('../approve-order-cancel/ApprovalStatus')
);

const ApproveVin = lazy(() => import('../approve-vin/index'));
const ApproveVinStatus = lazy(() => import('../approve-vin/ApprovalStatus'));

const ApproveVinExtension = lazy(
  () => import('../approve-vin-extension/index')
);
const ApproveVinExtensionStatus = lazy(
  () => import('../approve-vin-extension/ApprovalStatus')
);

const SpecialApproveBooking = lazy(
  () => import('../special-approve-booking/index')
);
const SpecialApproveBookingStatus = lazy(
  () => import('../special-approve-booking/ApprovalStatus')
);

const LostLead = lazy(() => import('../lost-lead/index'));
const LostLeadStatus = lazy(() => import('../lost-lead/ApprovalStatus'));

interface CustomProps {
  appSettingsState: AppSettingsStateViewModel;
}
const Routes: React.FunctionComponent<CustomProps> = (props) => {
  return (
    <Suspense fallback={<FullPageLoader text={'Loading...'} />}>
      <Switch>
        <Route path={'/:lang'} component={SubRoutes} />
        <Redirect
          to={{
            pathname: `${
              props.appSettingsState?.htmlDirection === 'ltr' ? 'en' : 'ar'
            }${RouteKeys.Home}`,
          }}
          from={RouteKeys.Home}
        />

        <Route exact path={RouteKeys.NotFound} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
const mapStateToProps = (state: any) => ({
  appSettingsState: state.appSettingsState,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  // redirectSave: (to: string) => {
  //   dispatch(authUserActions.redirectSave(to));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
