import React from 'react';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Routes from './routes/Routes';
import { useMasterData } from 'hooks/useMasterData';
import { api } from 'alg-ecom-frontend-core';
import FullPageLoader from 'components/loader/full-page-loader';
import Config from 'config';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import '../styles/base.scss';

const App: React.FC = () => {
  const masterData = useMasterData(api.appSettings, Config.franchise);



  return (
    <div>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
      />

      {(!masterData.loaded || masterData.loading) && <FullPageLoader />}
      <GoogleOAuthProvider
        clientId={
          '299150888981-nuqbqkgnes1j8924bleo2ktmlg4ci1p4.apps.googleusercontent.com'
        }
      >
        {masterData.loaded && <Routes />}
      </GoogleOAuthProvider>
    </div>
  );
};

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(App);
