// import api from '../api';
// import unauthorizedApiResponseInterceptor from '../api/interceptor';

import {
  authActions,
  commonActions,
  api,
  UseUnauthorizedApiResponseInterceptor,
} from 'alg-ecom-frontend-core';
import { State } from './interfaces';

const initialize = (store: any) => {
  const state: State = store.getState();
  UseUnauthorizedApiResponseInterceptor(() => {
    store.dispatch(authActions.logout());
    store.dispatch(commonActions.clearData());
    api.setToken(undefined);
    api.setLanguageID(undefined);
    api.setWebsiteID(undefined);
    api.setSubsiteID(undefined);
    window.location.reload();
  });

  if (state.authState.token) {
    api.setToken(state.authState.token);
    // store.dispatch(userActions.fetchProfile());
  }
  if (state.appSettingsState.languageID) {
    api.setLanguageID(state.appSettingsState.languageID);
  }
  // else if (
  //   state.appSettingsState.data &&
  //   state.appSettingsState.data?.languagesList?.length > 0
  // ) {
  //   api.setLanguageID(state.appSettingsState.data?.languagesList[0].languageID);
  //   store.dispatch(
  //     AppSettingsLanguageAction(
  //       state.appSettingsState.data?.languagesList[0].languageID
  //     )
  //   );
  // }

  if (state.appSettingsState.websiteID) {
    api.setWebsiteID(state.appSettingsState.websiteID);
  }
  //  else if (
  //   state.appSettingsState.data &&
  //   state.appSettingsState.data?.websiteList?.length > 0
  // ) {
  //   const id = state.appSettingsState.data?.websiteList?.find(
  //     (item) => item.websiteName === Config.franchise
  //   )?.websiteID;
  //   api.setWebsiteID(id);
  //   if (id) {
  //     store.dispatch(AppSettingsWebsiteAction(id));
  //   }
  // }

  if (state.appSettingsState.subsiteID) {
    api.setSubsiteID(state.appSettingsState.subsiteID);
  }
  //  else if (
  //   state.appSettingsState.data &&
  //   state.appSettingsState.data?.subsitesList?.length > 0
  // ) {
  //   const id = state.appSettingsState.data?.subsitesList?.find(
  //     (item) => item.storeName === Config.franchise
  //   )?.storeID;
  //   api.setSubsiteID(id);
  //   if (id) {
  //     store.dispatch(AppSettingsSubsiteAction(id));
  //   }
  // }
};

export default initialize;
